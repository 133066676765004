var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    attrs: {
      "id": "submit-to-others",
      "title": "Submit to others...",
      "description": "Select which regulator you would like to submit to",
      "has-footer": true
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "submit-others__footer d-flex align-items-center justify-content-between"
        }, [_c('BaButton', {
          staticClass: "border-0 submit-others__button--cancel",
          attrs: {
            "text": "Cancel",
            "variant": "none"
          },
          on: {
            "click": function ($event) {
              return _vm.$bvModal.hide('modal-submit-to-others');
            }
          }
        }), _c('BaButton', {
          staticClass: "border-0",
          class: [_vm.selectedRegulator ? 'submit-others__button--next' : 'submit-others__button--disabled'],
          attrs: {
            "text": "Next",
            "variant": "none",
            "disabled": !_vm.selectedRegulator
          },
          on: {
            "click": _vm.proceedToSubmitPage
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "submit-others pb-5"
  }, [_c('h3', {
    staticClass: "submit-others__title text-black-70"
  }, [_vm._v("Regulators")]), _c('div', {
    staticClass: "submit-others__regulators"
  }, _vm._l(_vm.regulators, function (regulator) {
    return _c('div', {
      key: regulator.id,
      staticClass: "position-relative d-flex flex-column align-items-center submit-others__regulator",
      class: [{
        'submit-others__regulator--selected': _vm.selectedRegulator === regulator.id
      }, {
        'submit-others__regulator--unselectable': regulator.isSubmitted
      }],
      on: {
        "click": function ($event) {
          return _vm.selectRegulator(regulator);
        }
      }
    }, [_c('span', {
      staticClass: "submit-others__regulator__radio d-block position-absolute"
    }, [regulator.isSubmitted ? _c('BaBaseIcon', {
      attrs: {
        "name": "ba-check",
        "height": "auto",
        "width": "auto"
      }
    }) : _c('BaRadio', {
      attrs: {
        "value": regulator.id,
        "option-value": regulator.id,
        "name": "regulator",
        "id": regulator.id
      },
      model: {
        value: _vm.selectedRegulator,
        callback: function ($$v) {
          _vm.selectedRegulator = $$v;
        },
        expression: "selectedRegulator"
      }
    })], 1), _c('img', {
      staticClass: "submit-others__regulator__image",
      attrs: {
        "src": require(`@/assets/pngs/${regulator.image}`)
      }
    }), _c('h5', {
      staticClass: "text-center text-black-90 submit-others__regulator__name"
    }, [_vm._v(_vm._s(regulator.name))])]);
  }), 0)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }