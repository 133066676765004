
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            fineStatus: "outstanding",
            tabs: [
                { key: "outstanding", value: "Outstanding" },
                { key: "paid", value: "Paid" },
                { key: "all", value: "All" },
            ],
            fines: [
                { regulator: "FRC", dateFined: "19/11/2022", amount: "N150,000", status: "outstanding" },
                { regulator: "PENCOM", dateFined: "19/11/2022", amount: "N150,000", status: "outstanding" },
                // { regulator: "PENCOM", dateFined: "19/11/2022", amount: "N150,000", status: "paid" },
                // { regulator: "PENCOM", dateFined: "19/11/2022", amount: "N150,000", status: "outstanding" },
            ],
        };
    },
    computed: {
        filteredFines(): any {
            if (this.fineStatus === "all") {
                return this.fines;
            }
            return this.fines.filter((fine) => fine.status === this.fineStatus);
        },
    },
});
