var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    staticClass: "selectable-modal selectable-modal_container",
    attrs: {
      "id": 'flagged-comment',
      "title": 'View Flagged Comment',
      "description": _vm.description,
      "custom-content-class": "overflow-scroll selectable-modal__content"
    }
  }, [_c('b-row', [_c('b-col', [_c('div', {
    staticClass: "text-black h6"
  }, [_vm._v("Comment:")]), _c('div', {
    staticClass: "border-2 px-3"
  }, [_vm._v(_vm._s(_vm.comment))])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }