var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-loader-wrapper', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }, [_c('section', {
    staticClass: "sectorial-regulator d-flex flex-column bg-white"
  }, _vm._l(_vm.principles, function (principle) {
    return _c('div', {
      key: principle.title,
      staticClass: "principle"
    }, [_c('h4', {
      staticClass: "principle__title bg-gray-20 text-black-70"
    }, [_vm._v(_vm._s(principle.title))]), principle.description && !['Y. Other documentation', 'Other documentation'].includes(principle.title) ? _c('p', {
      staticClass: "principle__description bg-gray-10 text-black-80"
    }, [_vm._v(" " + _vm._s(principle.description) + " ")]) : _vm._e(), !['Y. Other documentation', 'Other documentation'].includes(principle.title) ? _c('div', {
      staticClass: "principle__list d-flex flex-column"
    }, _vm._l(principle.questions, function (question) {
      return _c('SectorialRequirementOutput', {
        key: question.entry,
        attrs: {
          "question": question
        }
      });
    }), 1) : _c('div', [_vm.documents.length > 0 ? _c('ba-upload-field', {
      attrs: {
        "saved-files": _vm.documents,
        "is-removable": false,
        "id": "documents"
      }
    }) : _c('p', {
      staticClass: "mb-0 no-documents font-italic"
    }, [_vm._v("None provided")])], 1)]);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }