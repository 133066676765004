
import Vue from "vue";

export default Vue.extend({
    props: {
        question: { type: Object, required: true, default: () => ({}) },
    },
    computed: {
        questionHaveBooleanInput(): boolean {
            return [9, 15].includes(this.question.dataType);
        },
        questionHaveDropdownInput(): boolean {
            return [15, 16].includes(this.question.dataType);
        },
    },
});
