var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    staticClass: "selectable-modal selectable-modal_container",
    attrs: {
      "id": 'message',
      "title": 'Message',
      "description": `The person who declined this approval left a message for you`,
      "custom-content-class": "overflow-scroll selectable-modal__content"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "message__header"
  }, [_vm._v("Message")]), _c('p', {
    staticClass: "message__description"
  }, [_vm._v(_vm._s(_vm.message))])])], 1), _c('b-row', {
    staticClass: "mt-5"
  }, [_c('b-col', {
    staticClass: "d-flex flex-row justify-content-between align-items-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-button', {
    staticClass: "text-black",
    attrs: {
      "text": "Close",
      "variant": "light"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide('modal-message');
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }