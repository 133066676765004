
import Vue from "vue";

enum IReportStatus {
    DRAFT = 0,
    SIGNED = 12,
    FINALISED = 6,
    PROCESSING = 7,
    AWAITING_SIGNING = 3,
    REGULATOR_APPROVAL_IN_PROGRESS = 5,
}

export default Vue.extend({
    props: {
        report: {
            type: Object,
            required: true,
        },
    },
    computed: {
        reportStatus(): string {
            let res = "Draft";
            if (this.report.reportStatus === IReportStatus.DRAFT && !this.report.dateSubmittedInternally && this.report.regulatorPushCount === 0 && this.report.regulatorApprovalStatus === 0)
                res = "Draft";
            if (this.report.reportStatus === IReportStatus.REGULATOR_APPROVAL_IN_PROGRESS) res = "Submitted to the FRC";
            else if (this.report.reportStatus === IReportStatus.DRAFT && this.report.dateSubmittedInternally) res = "Draft - Declined";
            else if (this.report.reportStatus === IReportStatus.SIGNED) res = "Signed";
            else if (this.report.reportStatus === IReportStatus.FINALISED) res = "Finalised";
            else if (this.report.reportStatus === IReportStatus.PROCESSING) res = "Awaiting Review";
            else if (this.report.reportStatus === IReportStatus.AWAITING_SIGNING) res = "Awaiting Signing";
            return res;
        },
        badgeColor() {
            let res = { bg: "blue-10", text: "blue-90" };
            if (this.report.reportStatus === IReportStatus.DRAFT && this.report.regulatorPushCount === 0) res = { bg: "blue-10", text: "blue-90" };
            else if ((this.report.reportStatus === IReportStatus.REGULATOR_APPROVAL_IN_PROGRESS || this.report.reportStatus === IReportStatus.SIGNED) && this.report.regulatorApprovalStatus !== 3)
                res = { bg: "green-10", text: "green-70" };
            else if ((this.report.reportStatus === IReportStatus.REGULATOR_APPROVAL_IN_PROGRESS || this.report.reportStatus === IReportStatus.SIGNED) && this.report.regulatorApprovalStatus === 3)
                res = { bg: "red-10", text: "red-90" };
            else if (this.report.reportStatus === IReportStatus.PROCESSING) res = { bg: "yellow-10", text: "yellow-150" };
            else if (this.report.reportStatus === IReportStatus.AWAITING_SIGNING) res = { bg: "yellow-10", text: "yellow-150" };
            return res;
        },
    },
});
