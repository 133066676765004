var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-loader-wrapper', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }, [_vm.report ? _c('div', {
    staticClass: "mt-body py-6 container-fluid-custom w-100"
  }, [_c('BaBreadcrumb', {
    attrs: {
      "text-one": "Dashboard",
      "text-two": "View Report"
    }
  }), _c('div', {
    staticClass: "report-header d-flex flex-column"
  }, [_c('div', {
    staticClass: "report-header--top d-flex align-items-start justify-content-between"
  }, [_c('div', {
    staticClass: "report-header--top-left"
  }, [_c('h3', {
    staticClass: "report-header__title mb-0 text-black-90"
  }, [_vm._v(_vm._s(_vm.report.reportInformation ? _vm.report.reportInformation.reportTitle.replace("[", "(").replace("]", ")") : ""))])]), _c('div', {
    staticClass: "report-header--top-right d-flex align-items-center position-relative"
  }, [_c('div', {
    staticClass: "d-flex align-items-center gap-8"
  }, [_c('ReportStatus', {
    attrs: {
      "report": _vm.report.reportInformation
    }
  }), _vm.numberOfSubmittedSectorialRegulators > 0 ? _c('BaBadge', {
    attrs: {
      "variant": "success",
      "text": `+${_vm.numberOfSubmittedSectorialRegulators}`
    }
  }) : _vm._e()], 1), _c('BaBaseDropdownToggler', {
    attrs: {
      "variant": "vertical"
    },
    nativeOn: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.showReportOptions = !_vm.showReportOptions;
      }
    }
  }), _c('BaBaseDropdown', {
    attrs: {
      "show": _vm.showReportOptions,
      "width": "222px",
      "top": "40px",
      "right": "0px"
    },
    on: {
      "outside-clicked": function ($event) {
        _vm.showReportOptions = false, _vm.showContinueEditingDropdown = false;
      }
    }
  }, _vm._l(_vm.itemOptions, function (itemOption, ix) {
    return _c('BaBaseDropdownItem', {
      key: ix,
      attrs: {
        "text": itemOption.text
      },
      nativeOn: {
        "click": function ($event) {
          $event.stopPropagation();
          return itemOption.action.apply(null, arguments);
        }
      },
      scopedSlots: _vm._u([{
        key: "icon",
        fn: function () {
          return [itemOption.text === 'Continue Editing' && _vm.report.reportInformation.reportStatus !== _vm.IReportStatus.DRAFT ? _c('BaBaseIcon', {
            attrs: {
              "name": "ba-chevron-right",
              "height": "auto",
              "width": "auto"
            }
          }) : _vm._e()];
        },
        proxy: true
      }], null, true)
    });
  }), 1)], 1)]), _c('div', {
    staticClass: "report-header--bottom d-flex align-items-center justify-content-between flex-wrap"
  }, [_c('div', {
    staticClass: "report-header--bottom-left d-flex align-items-center flex-wrap"
  }, [_c('div', {
    staticClass: "report-header__item d-flex flex-column"
  }, [_c('p', {
    staticClass: "mb-0 text-black-70 small-text"
  }, [_vm._v("Report ID")]), _c('h6', {
    staticClass: "mb-0 text-black-100"
  }, [_vm._v(_vm._s(_vm.report.reportInformation.reportCode))])]), _c('div', {
    staticClass: "report-header__item d-flex flex-column"
  }, [_c('p', {
    staticClass: "mb-0 text-black-70 small-text"
  }, [_vm._v("Created By")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('BaUser', {
    attrs: {
      "user": _vm.report.reportInformation.createdByUser
    }
  }), _vm._v("   "), _c('span', {
    staticClass: "small-text text-black-70"
  }, [_vm._v("on")]), _vm._v("   "), _c('span', {
    staticClass: "small-text text-black-100"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.report.reportInformation.dateCreated, _vm.DateTime.DATE_SHORT)) + " ")])], 1)]), _vm.report.reportInformation.regulatorPushCount > 0 ? _c('div', {
    staticClass: "report-header__item d-flex flex-column"
  }, [_c('p', {
    staticClass: "mb-0 text-black-70 small-text"
  }, [_vm._v("Date Submitted to FRC")]), _c('h6', {
    staticClass: "mb-0 text-black-100"
  }, [_vm._v(" " + _vm._s(_vm.DateTime.fromISO(_vm.report.reportInformation.dateSentToRegulator).toFormat("dd/LL/yyyy, h:mma")) + " ")])]) : _vm._e(), _vm.currentDeskUsers.length > 0 ? _c('div', {
    staticClass: "report-header__item d-flex flex-column position-relative cursor-pointer",
    on: {
      "click": function ($event) {
        return _vm.toggleShowCurrentDeskUsersDropdown(!_vm.showCurrentDeskUsersDropdown);
      }
    }
  }, [_c('p', {
    staticClass: "mb-0 text-black-70 small-text"
  }, [_vm._v("Current Desk")]), _vm.currentDeskUsers.length > 1 ? _c('div', {
    staticClass: "d-flex align-items-center gap-2"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, _vm._l(_vm.currentDeskUsers, function (user, index) {
    return _c('span', {
      key: index,
      staticClass: "d-block negative-margin"
    }, [_c('BaUser', {
      attrs: {
        "showName": false,
        "user": user.user
      }
    })], 1);
  }), 0), _c('span', {
    staticClass: "users-count text-black-100 d-flex align-items-center gap-4"
  }, [_vm._v(" " + _vm._s(_vm.currentDeskUsers.length) + " people "), _c('BaBaseIcon', {
    attrs: {
      "name": "ba-new-arrow",
      "height": "auto",
      "width": "auto"
    }
  })], 1)]) : _vm.currentDeskUsers.length === 1 ? _c('div', {
    staticClass: "d-flex gap-4 align-items-center"
  }, [_c('BaUser', {
    attrs: {
      "user": _vm.currentDeskUsers[0].user
    }
  }), _c('BaBaseIcon', {
    attrs: {
      "name": "ba-new-arrow",
      "height": "auto",
      "width": "auto"
    }
  })], 1) : _vm._e(), _c('BaBaseDropdown', {
    attrs: {
      "show": _vm.showCurrentDeskUsersDropdown,
      "width": "220px",
      "top": "60px",
      "left": "0"
    }
  }, _vm._l(_vm.currentDeskUsers, function (user, index) {
    return _c('div', {
      key: index,
      staticClass: "d-flex flex-column gap-10 desk-user"
    }, [_c('BaUser', {
      attrs: {
        "user": user.user
      }
    }), _c('div', {
      staticClass: "d-flex items-center flex-wrap gap-8"
    }, _vm._l(user.regulators, function (regulator) {
      return _c('BaBadge', {
        key: regulator,
        attrs: {
          "variant": "success",
          "text": regulator.toUpperCase()
        }
      });
    }), 1)], 1);
  }), 0)], 1) : _vm._e()]), _c('div', {
    staticClass: "report-header--bottom-right"
  }, [_c('div', {
    staticClass: "report-header__actions d-flex align-items-center"
  }, [_vm.canSubmitToRegulator && !_vm.isLoading ? _c('BaButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "text-black",
    attrs: {
      "loading": _vm.isSubmittingToRegulator,
      "text": "Submit to the FRC",
      "id": "BA_AMP_SUBMIT_TO_FRC",
      "variant": "warning",
      "title": 'Submit to the Financial Reporting Council of Nigeria',
      "disabled": _vm.isSubmittingToRegulator || !_vm.canSubmitToRegulator || _vm.disableSubmission
    },
    on: {
      "click": _vm.submitToRegulator
    }
  }) : _vm._e(), _vm.canSignReport && !_vm.isLoading ? [_c('BaButton', {
    staticClass: "border-0 bg-green-50 text-white",
    attrs: {
      "disabled": _vm.isSigning || !_vm.canSignReport,
      "loading": _vm.isSigning,
      "text": "Sign Report"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.show('modal-upload-signature');
      }
    }
  }), _c('BaButton', {
    staticClass: "border-0 bg-black-100 text-white",
    attrs: {
      "text": "Send Back"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.show('modal-send-back');
      }
    }
  })] : _vm._e(), _vm.canApproveReport && !_vm.isLoading && _vm.isApproveReviewVisible ? _c('BaButton', {
    staticClass: "border-0 bg-green-50 text-white",
    attrs: {
      "loading": _vm.isApprovingReport,
      "text": "Approve",
      "id": "BA_AMP_APPROVE_REVIEW"
    },
    on: {
      "click": _vm.checkForUnresolvedCommentsBeforeApproval
    }
  }) : _vm._e(), _vm.canApproveReport && !_vm.isLoading && _vm.isApproveReviewVisible || _vm.currentSectorialRegulatorIsReviewable ? _c('div', {
    staticClass: "position-relative"
  }, [_c('BaButton', {
    staticClass: "bg-black-100 border-0 text-white base-dropdown__toggler",
    attrs: {
      "text": `${_vm.isShowingReviewComments ? 'Hide' : 'Add'} Review Comments`,
      "id": "BA_AMP_ADD_COMMENT",
      "padding": "split-buttons"
    },
    on: {
      "click": _vm.toggleReviewComment
    },
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('span', {
          staticClass: "split-buttons",
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              _vm.showCommentsSplitButtons = !_vm.showCommentsSplitButtons;
            }
          }
        }, [_c('BaBaseIcon', {
          attrs: {
            "name": "ba-chevron-down",
            "height": "auto",
            "width": "auto"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 1161056977)
  }), _c('BaBaseDropdown', {
    attrs: {
      "show": _vm.showCommentsSplitButtons,
      "width": "242px",
      "left": "0",
      "top": "51px"
    },
    on: {
      "outside-clicked": function ($event) {
        _vm.showCommentsSplitButtons = false;
      }
    }
  }, [!_vm.isShowingReviewComments ? _c('BaBaseDropdownItem', {
    attrs: {
      "text": "Send back with comments",
      "font-weight": "500"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.toggleReviewComment.apply(null, arguments);
      }
    }
  }) : _vm._e(), _c('BaBaseDropdownItem', {
    attrs: {
      "text": "Send back without comments",
      "font-weight": "500"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.sendReportBackToCreator(true);
      }
    }
  })], 1)], 1) : _vm._e(), _vm.currentSectorialRegulatorIsReviewable || _vm.currentSectorialRegulatorIsSubmittable ? [_vm.currentSectorialRegulatorIsSubmittable ? _c('BaButton', {
    staticClass: "text-black",
    attrs: {
      "loading": _vm.isSubmittingToRegulator,
      "text": `Submit to ${_vm.currentTab.value.toUpperCase()}`,
      "variant": "warning",
      "disabled": _vm.isSubmittingToRegulator
    },
    on: {
      "click": _vm.submitToSectorialRegulator
    }
  }) : _vm._e(), _vm.currentSectorialRegulatorIsReviewable ? _c('BaButton', {
    staticClass: "border-0 bg-green-50 text-white",
    attrs: {
      "loading": _vm.isApprovingReport,
      "text": "Approve",
      "id": "BA_AMP_APPROVE_REVIEW"
    },
    on: {
      "click": _vm.checkForUnresolvedCommentsBeforeApproval
    }
  }) : _vm._e()] : _vm._e()], 2)])])]), _c('hr'), _c('b-row', {
    staticClass: "bg-white mx-0 mb-5 w-100",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('b-nav', {
    staticClass: "flex-nowrap",
    attrs: {
      "vertical": ""
    }
  }, [_vm._l(_vm.tabs, function (tab, ix) {
    return _c('b-nav-item', {
      key: ix,
      staticClass: "nav-item",
      attrs: {
        "to": {
          query: {
            tab: tab.value
          }
        },
        "active": _vm.isActiveTab(tab.value),
        "replace": "",
        "active-class": "text-black-70"
      }
    }, [_c('div', {
      staticClass: "nav-item-text d-flex align-items-center justify-content-between",
      class: {
        'text-black-70': !_vm.isActiveTab(tab.value),
        'text-white nav-item-text--active': _vm.isActiveTab(tab.value),
        'justify-content-between': tab.value === 'pencom'
      }
    }, [_vm._v(" " + _vm._s(tab.text) + " "), tab.value === 'principles' && _vm.count > 0 ? _c('span', {
      staticClass: "bg-danger rounded-circle ml-2 px-2 py-2 text-white"
    }, [_vm._v(_vm._s(_vm.count))]) : _vm._e(), ['pencom', 'naicom', 'sec', 'ngx', 'cbn'].includes(tab.value) && tab.showBadge ? _c('BaBadge', {
      attrs: {
        "text": tab.status
      }
    }) : _vm._e()], 1)]);
  }), _c('div', {
    staticClass: "w-100 nav-item__divider"
  })], 2)], 1), !_vm.isDownloading ? _c('b-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c(_vm.currentTab.component, _vm._b({
    tag: "component",
    attrs: {
      "flaggedSections": _vm.flaggedSections[_vm.currentTab.component],
      "isRegulatorValid": _vm.checkRegulatorStatusComments,
      "isRegulatorValidFlags": _vm.checkRegulatorStatusFlags
    },
    on: {
      "clicked": _vm.setFlaggedProperties
    }
  }, 'component', {
    report: _vm.report,
    isLoading: _vm.isLoading,
    currentTab: _vm.currentTab
  }, false))], 1) : _c('b-col', [_c('ba-loader-wrapper', {
    attrs: {
      "isLoading": _vm.isDownloading
    }
  })], 1), _c('b-col', {
    staticClass: "d-none",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    ref: "document",
    attrs: {
      "id": "doc1"
    }
  }, [_c('div', {
    staticClass: "px-4",
    attrs: {
      "aria-label": "pdf-page-1"
    }
  }, [_c('p', {
    staticClass: "mb-1 company-text"
  }, [_vm._v("Company Name: " + _vm._s(_vm.companyData.companyProfile.name))]), _c('p', [_vm._v("RC Number: RC (" + _vm._s(_vm.companyData.companyProfile.rcNumber) + ")")])]), _c('company', {
    attrs: {
      "aria-label": "pdf-page-1",
      "report": _vm.report,
      "isLoading": _vm.isLoading,
      "flaggedSections": _vm.flaggedSections['company']
    }
  }), _c('documentation', {
    attrs: {
      "aria-label": "pdf-page-2",
      "report": _vm.report,
      "isLoading": _vm.isLoading,
      "flaggedSections": _vm.flaggedSections['documentation']
    }
  }), _c('stakeholders', {
    attrs: {
      "aria-label": "pdf-page-3",
      "report": _vm.report,
      "isLoading": _vm.isLoading,
      "flaggedSections": _vm.flaggedSections['stakeholders']
    }
  }), _c('structure', {
    attrs: {
      "aria-label": "pdf-page-4",
      "report": _vm.report,
      "isLoading": _vm.isLoading,
      "flaggedSections": _vm.flaggedSections['structure']
    }
  }), _vm._l(_vm.report.principles, function (principle, ix) {
    return _c('div', {
      key: ix,
      attrs: {
        "aria-label": `pdf-page-${ix + 6}`
      }
    }, [_c('p', {
      staticClass: "structure-title"
    }, [_vm._v(_vm._s(principle.name) + " : " + _vm._s(principle.title))]), _c('p', {
      staticClass: "principle-desc"
    }, [_vm._v(_vm._s(principle.description))]), _vm._l(principle.inputModels, function (inputModel, idx) {
      return _c('div', {
        key: idx,
        staticClass: "mb-4"
      }, [_c('question-output', {
        attrs: {
          "inputModel": inputModel,
          "principle": principle,
          "index": idx,
          "isRegulatorValid": false
        },
        on: {
          "inputModel": _vm.sendFlaggedProps
        }
      })], 1);
    })], 2);
  }), _c('certification', {
    attrs: {
      "aria-label": "pdf-page-80",
      "report": _vm.report,
      "isLoading": _vm.isLoading,
      "flaggedSections": _vm.flaggedSections['certification']
    }
  })], 2)]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('upload-signature-modal', {
    attrs: {
      "isCreating": _vm.isSigning
    },
    on: {
      "completed": function ($event) {
        return _vm.signReport($event);
      }
    }
  })], 1)], 1), _c('b-col', [_c('create-flagged-comment', {
    attrs: {
      "description": _vm.description,
      "comment": _vm.comment
    }
  })], 1), _c('b-col', [_c('payment-modal', {
    attrs: {
      "totalAmount": _vm.totalAmount,
      "outstandingFees": _vm.outstandingFees,
      "reportID": _vm.report.reportInformation.id,
      "regulatorID": _vm.report.reportInformation.regulator.id,
      "templateID": _vm.report.reportInformation.template.id
    }
  })], 1), _c('b-col', [_c('message-modal', {
    attrs: {
      "message": _vm.report.reportInformation.reviewComment
    }
  })], 1), _c('KeepAlive', [_vm.isShowingReviewComments ? _c('Draggable', {
    attrs: {
      "position": {
        right: '24px',
        bottom: '100px'
      }
    }
  }, [_c('ReportComments', {
    attrs: {
      "report": _vm.report,
      "sectorial-regulator": _vm.currentSectorialRegulator
    },
    on: {
      "show-review-confirmation": _vm.openReviewConfirmationModal
    }
  })], 1) : _vm._e()], 1), _c('ReportConfirmationModal', {
    attrs: {
      "is-loading": _vm.isApprovingReport,
      "confirmation-type": "approve-report"
    },
    on: {
      "approve-report": _vm.approveReport
    }
  }), _c('ReportConfirmationModal', {
    attrs: {
      "is-loading": _vm.isSendingBackToCreator,
      "confirmation-type": "submit-review"
    },
    on: {
      "send-back-to-creator": _vm.sendReportBackToCreator
    }
  }), _c('SendBackModal', {
    on: {
      "report-sent": _vm.fetchReportById
    }
  })], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }