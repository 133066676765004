
import Vue from "vue";
import QuestionOutput from "@/app/components/reports/QuestionOutput.vue";

export default Vue.extend({
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        report: {
            type: Object,
            default: () => ({}),
        },
        canDownload: {
            type: Boolean,
            default: false,
        },
        isRegulatorValid: {
            type: Boolean,
            default: false,
        },
        isRegulatorValidFlags: {
            type: Boolean,
            default: false,
        },
        isPreview: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        QuestionOutput,
    },
    data() {
        return {};
    },
    methods: {
        sendFlaggedProps(value: { description: string; comment: string }) {
            this.$emit("clicked", value);
        },
    },
});
