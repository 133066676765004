var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    class: `text-${_vm.badgeColor.text} bg-${_vm.badgeColor.bg}`,
    attrs: {
      "variant": _vm.badgeColor.bg
    }
  }, [_vm._v(_vm._s(_vm.reportStatus))]);

}
var staticRenderFns = []

export { render, staticRenderFns }