var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    attrs: {
      "id": "send-back",
      "title": "Send Back",
      "description": "Add a message to your decision so the receiver can make changes",
      "has-footer": true,
      "apply-body-classes": false
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('BaButton', {
          staticClass: "border-0 bg-gray-40 text-black-90",
          attrs: {
            "text": "Cancel",
            "variant": "none",
            "disabled": _vm.isLoading
          },
          on: {
            "click": function ($event) {
              return _vm.$bvModal.hide('modal-submit-to-others');
            }
          }
        }), _c('BaButton', {
          staticClass: "border-0 bg-black-100 text-white",
          attrs: {
            "text": "Send back to creator",
            "variant": "none",
            "disabled": !_vm.comment || _vm.isLoading,
            "loading": _vm.isLoading
          },
          on: {
            "click": _vm.sendBackToCreator
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "send-back"
  }, [_c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.sendBackToCreator.apply(null, arguments);
      }
    }
  }, [_c('ba-textarea', {
    attrs: {
      "label": "Message",
      "placeholder": "Enter your message",
      "usage": "comment",
      "required": ""
    },
    model: {
      value: _vm.comment,
      callback: function ($$v) {
        _vm.comment = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "comment"
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }