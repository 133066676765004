
import Vue from "vue";
import { mapGetters } from "vuex";
import html2pdf from "html2pdf.js";
import { DateTime } from "luxon";
import Company from "@/app/components/tabs/view-report/Company.vue";
import Documentation from "@/app/components/tabs/view-report/Documentation.vue";
import Stakeholders from "@/app/components/tabs/view-report/Stakeholders.vue";
import Structure from "@/app/components/tabs/view-report/Structure.vue";
import SectorialRegulator from "@/app/components/tabs/view-report/SectorialRegulator.vue";
import UploadSignatureModal from "@/app/components/modals/UploadSignatureModal.vue";
import PaymentModal from "@/app/components/modals/PaymentModal.vue";
import CreateFlaggedComment from "@/app/components/modals/viewFlaggedComment.vue";
import Principles from "@/app/components/tabs/view-report/Principles.vue";
import Certification from "@/app/components/tabs/view-report/Certification.vue";
import Pencom from "@/app/components/tabs/view-report/Pencom.vue";
import ReportItem from "@/app/components/reports/ReportItem.vue";
import ReportStatus from "@/app/components/reports/ReportStatus.vue";
import ReportComments from "@/app/components/reports/ReportComments.vue";
import MessageModal from "@/app/components/modals/MessageModal.vue";
import QuestionOutput from "@/app/components/reports/QuestionOutput.vue";
import ReportConfirmationModal from "@/app/components/modals/ReportConfirmationModal.vue";
import SubmitToOthersModal from "@/app/components/modals/SubmitToOthersModal.vue";
import ReportHistoryModal from "@/app/components/modals/ReportHistoryModal.vue";
import FinesPenaltiesModal from "@/app/components/modals/FinesPenaltiesModal.vue";
import SendBackModal from "@/app/components/modals/SendBackModal.vue";
import Draggable from "@/app/components/Draggable.vue";
import { HOME } from "../../routes/endpoints";
import ReportService from "../../../api/report-service";
import { formatDate } from "../../../utils/helpers";

enum IReportStatus {
    DRAFT = 0,
    SIGNED = 12,
    FINALISED = 6,
    PROCESSING = 7,
    AWAITING_SIGNING = 3,
}

enum IRegulatorStatus {
    COMPLIANT = 2,
    NON_COMPLIANT = 3,
    ADJUSTMENT_NEEDED = 4,
}

export default Vue.extend({
    components: {
        Company,
        Documentation,
        Stakeholders,
        Structure,
        Principles,
        Certification,
        Pencom,
        UploadSignatureModal,
        CreateFlaggedComment,
        ReportItem,
        PaymentModal,
        MessageModal,
        QuestionOutput,
        ReportComments,
        SubmitToOthersModal,
        Draggable,
        ReportStatus,
        ReportConfirmationModal,
        SectorialRegulator,
        ReportHistoryModal,
        FinesPenaltiesModal,
        SendBackModal,
    },
    data() {
        return {
            IReportStatus,
            count: 0,
            totalAmount: 0,
            outstandingFees: [] as any[],
            description: "",
            comment: "",
            isBackToReviewer: false,
            isApprovingReport: false,
            isDecliningReview: false,
            isSubmittingToRegulator: false,
            report: {
                reportInformation: {
                    reportTitle: "",
                    reportStatus: null,
                    reportCode: "",
                    createdByUser: {},
                    currentDeskUser: {
                        email: "",
                        firstName: "",
                        id: "",
                        lastName: "",
                        profileImgId: "",
                        userName: "",
                    },
                    signatoryIds: [],
                    regulatorApprovalStatus: 0,
                    regulatorPushCount: 0,
                    regulator: {},
                    template: {},
                },
                companyDetails: {},
                licenses: [],
                policies: [],
                registrarCompanies: [],
                boardConsultants: [],
                governanceConsultants: [],
                auditors: [],
                charters: [],
                structure: {
                    commitees: [],
                    structurePositions: [],
                },
                principles: [],
                signatures: [],
                comments: [],
                sectorialRequirementsReviews: [] as any[],
            },
            isLoading: false,
            isDownloading: false,
            isSigning: false,
            DateTime,
            flaggedSections: {
                company: {},
                structure: {},
                stakeholders: {},
                documentation: {},
                certification: {},
            },
            regulatorStatusComments: [IRegulatorStatus.COMPLIANT, IRegulatorStatus.NON_COMPLIANT, IRegulatorStatus.ADJUSTMENT_NEEDED],
            regulatorStatusFlags: [IRegulatorStatus.COMPLIANT, IRegulatorStatus.NON_COMPLIANT],
            isShowingReviewComments: false,
            showContinueEditingDropdown: false,
            isSendingBackToCreator: false,
            showReportOptions: false,
            confirmationType: "",
            showCommentsSplitButtons: false,
            showCurrentDeskUsersDropdown: false,
            disableSubmission: false,
        };
    },
    computed: {
        ...mapGetters({ profileData: "auth/getUserData", companyData: "company/getProfile" }),
        tabs(): any {
            const tabs: any[] = [
                { text: "Details", value: "company", component: "company", regulator: "frc" },
                { text: "Governance Structure", value: "structure", component: "structure", regulator: "frc" },
                { text: "Governance Stakeholders", value: "stakeholders", component: "stakeholders", regulator: "frc" },
                { text: "Documentation", value: "documentation", component: "documentation", regulator: "frc" },
                { text: "Principles", value: "principles", component: "principles", regulator: "frc" },
                { text: "Execution", value: "certification", component: "certification", regulator: "frc" },
            ];
            // if (this.report.sectorialRequirementsReviews && this.report.sectorialRequirementsReviews.length > 0) {
            //     const mapped: any[] = this.report.sectorialRequirementsReviews.map((requirement: any) => {
            //         const editStatus: Record<any, string> = { 0: "Draft", 1: "InReview", 2: "Approved" };
            //         return {
            //             text: `(${requirement.regulatorId.toUpperCase()})`,
            //             value: requirement.regulatorId,
            //             component: "sectorialRegulator",
            //             status: editStatus[requirement.editStatus],
            //             showBadge: !requirement.currentRegulatorDeskId,
            //         };
            //     });

            //     tabs.push(...mapped);
            // }
            return tabs;
        },
        isAdmin(): boolean {
            return this.profileData?.roles?.includes("Admin");
        },
        isCreator(): boolean {
            return (this.report.reportInformation as any).createdByUser.id === this.profileData.userId;
        },
        canApproveReport(): boolean {
            return this.report.reportInformation?.reportStatus === IReportStatus.PROCESSING && this.report?.reportInformation?.currentDeskUser?.id === this.profileData.userId;
        },
        isApproveReviewVisible(): boolean {
            return this.report.reportInformation?.reportStatus === IReportStatus.PROCESSING;
        },
        canSignReport(): boolean {
            let result;
            if (this.report?.reportInformation?.currentDeskUser) {
                result =
                    this.report.reportInformation?.reportStatus === IReportStatus.AWAITING_SIGNING &&
                    this.report.signatures?.find((signature: any) => signature.userId === this.profileData.userId) != null &&
                    this.report?.reportInformation?.currentDeskUser?.id === this.profileData.userId;
            } else {
                result =
                    this.report.reportInformation?.reportStatus === IReportStatus.AWAITING_SIGNING &&
                    this.report.signatures?.find((signature: any) => signature.userId === this.profileData.userId) != null;
            }
            return result;
        },
        isDirectorTag(): boolean {
            return (
                this.report.reportInformation?.reportStatus === IReportStatus.AWAITING_SIGNING &&
                this.report.signatures?.find((signature: any) => signature.userId === this.profileData.userId) != null &&
                this.report?.reportInformation?.currentDeskUser?.id !== this.profileData.userId &&
                (this.profileData.position === "Managing Director" || this.profileData.position === "Executive Director" || this.profileData.position === "Board Chairman")
            );
        },
        // Send Report back
        canSubmitToRegulator(): boolean {
            return this.report.reportInformation?.reportStatus === IReportStatus.SIGNED && (this.profileData.roles?.includes("ReportCreator") || this.profileData.roles?.includes("Admin"));
        },
        sectorialRegulatorsInDraft(): any {
            return [];
            // return this.report.sectorialRequirementsReviews.filter((review: any) => review.editStatus === 0 && !review.currentRegulatorDeskId);
        },
        itemOptions() {
            const { id } = this.$route.params;

            const val = [
                // {
                //     text: "Report History",
                //     action: () => {
                //         this.$bvModal.show("modal-report-history");
                //     },
                // },
                {
                    text: "Download As PDF",
                    action: () => {
                        this.isDownloading = true;
                        this.exportPdf();
                    },
                },
            ];

            // const canSubmitToOtherRegulators = this.report.reportInformation.regulatorPushCount > 0 && this.report.sectorialRequirementsReviews && this.report.sectorialRequirementsReviews.length < 5;

            // if (canSubmitToOtherRegulators && this.isCreator) {
            //     val.splice(0, 0, {
            //         text: "Submit to others...",
            //         action: () => {
            //             this.$bvModal.show("modal-submit-to-others");
            //         },
            //     });
            // }

            const canSubmitToOtherRegulators = this.report.reportInformation.regulatorPushCount > 0;

            if (canSubmitToOtherRegulators && this.isCreator) {
                val.splice(0, 0, {
                    text: "Submit to others...",
                    action: () => {
                        // this.$bvModal.show("modal-submit-to-others");
                    },
                });
            }

            // if (this.report.reportInformation.regulatorPushCount > 0 && this.sectorialRegulatorsInDraft.length > 0 && this.isCreator) {
            //     val.splice(canSubmitToOtherRegulators ? 1 : 0, 0, {
            //         text: "Continue Editing",
            //         action: () => {
            //             this.showContinueEditingDropdown = !this.showContinueEditingDropdown;
            //         },
            //     });
            // }

            if (this.report.reportInformation?.reportStatus === IReportStatus.DRAFT && (this.isAdmin || this.isCreator)) {
                val.splice(0, 0, {
                    text: "Continue Editing",
                    action: () => {
                        this.$router.push({ name: HOME.CREATE_REPORT.NAME, query: { id } });
                        this.clearReport();
                    },
                });
            }

            // val.push({
            //     text: "Fines/Penalties",
            //     action: () => {
            //         this.$bvModal.show("modal-fines-penalties");
            //     },
            // });

            return val;
        },
        currentTab(): { text: string; value: string; component: string } {
            const { tab }: { tab?: string } = this.$route.query;

            const res = this.tabs.find((tabItem: any) => tabItem.value === tab);

            return res ?? this.tabs[0];
        },
        checkRegulatorStatusComments(): boolean {
            return this.regulatorStatusComments.includes(this.report.reportInformation.regulatorApprovalStatus);
        },
        checkRegulatorStatusFlags(): boolean {
            return this.regulatorStatusFlags.includes(this.report.reportInformation.regulatorApprovalStatus);
        },
        currentDeskUsers(): any {
            // const sectorialRegulatorReviewers = this.report.sectorialRequirementsReviews
            //     .filter((review: any) => review.editStatus === 1)
            //     .map((regulator) => {
            //         const [firstName, lastName] = regulator.companyReviewerDetails.fullname.split(" ");
            //         return { firstName, lastName, email: regulator.companyReviewerDetails.email, profileImgId: regulator.companyReviewerDetails.profileImgId, regulatorId: regulator.regulatorId };
            //     });
            // const users: any[] = [];
            // eslint-disable-next-line no-plusplus
            // for (let index = 0; index < sectorialRegulatorReviewers.length; index++) {
            //     const user = sectorialRegulatorReviewers[index];
            //     const userIndex = users.findIndex((item: any) => item.user.email === user.email);
            //     if (userIndex === -1) {
            //         users.push({ user: { ...user }, regulators: [user.regulatorId] });
            //     } else {
            //         users[userIndex].regulators.push(user.regulatorId);
            //     }
            // }
            // if (users && users.length > 0) return users;
            if (this.report.reportInformation.currentDeskUser) return [{ user: this.report.reportInformation.currentDeskUser, regulators: ["FRC"] }];
            return [];
        },
        actionIsForSectorialRegulator(): boolean {
            return ["sec", "ngx", "pencom", "cbn", "naicom"].includes(this.currentTab.value);
        },
        currentSectorialRegulator(): any {
            return {};
            // const regulator = this.report.sectorialRequirementsReviews.find((review: any) => review.regulatorId === this.currentTab.value);
            // return regulator;
        },
        currentSectorialRegulatorIsReviewable(): boolean {
            return false;
            // return this.currentSectorialRegulator && this.currentSectorialRegulator?.companyReviewerDetails?.email === this.profileData.email && this.currentSectorialRegulator.editStatus === 1;
        },
        currentSectorialRegulatorIsSubmittable(): boolean {
            return false;
            // return this.currentSectorialRegulator && this.isCreator && this.currentSectorialRegulator.editStatus === 2;
        },
        numberOfSubmittedSectorialRegulators(): number {
            return 0;
            // return this.report.sectorialRequirementsReviews.filter((review: any) => review.currentRegulatorDeskId).length;
        },
    },
    methods: {
        formatDate,
        sendFlaggedProps(value: { description: string; comment: string }) {
            this.$emit("clicked", value);
        },
        clearReport() {
            this.$store.commit("reports/SET_EXISTING_REPORT", "");
        },
        isActiveTab(tab: string): boolean {
            return this.currentTab.value === tab;
        },
        toggleMessageModal() {
            this.$bvModal.show("modal-message");
        },
        async exportPdf() {
            this.isDownloading = true;

            this.$bvToast.toast("The document is downloading, this might take a while. Remain on this tab while this happens and kindly try again if it exceeds 4 mins", {
                title: "Document downloading",
                variant: "success",
                solid: true,
            });

            const pages = Array.from(document.querySelectorAll('div[aria-label^="pdf-page-"]'));

            const pdfOptions = {
                margin: 0.5,
                filename: `${this.report.reportInformation?.reportCode} - NCCG Report ${new Date().toISOString().slice(0, 19)}.pdf`,
                image: { type: "jpeg", quality: 0.98 },
                html2canvas: {
                    dpi: 192,
                    letterRendering: true,
                },
                jsPDF: {
                    unit: "in",
                    format: "letter",
                    orientation: "portrait",
                },
            };

            const downloadPDF = (elements: any, options: any) => {
                let worker = html2pdf().set(options).from(elements[0]);

                if (elements.length > 1) {
                    worker = worker.toPdf(); // worker is now a jsPDF instance

                    // add each element/page individually to the PDF render process
                    elements.slice(1).forEach((element: any, index: number) => {
                        worker = worker
                            .get("pdf")
                            .then((pdf: any) => {
                                pdf.addPage();
                            })
                            .from(element)
                            .toContainer()
                            .toCanvas()
                            .toPdf();
                    });
                }

                worker = worker.save().then(() => {
                    this.isDownloading = false;
                });
            };
            await downloadPDF(pages, pdfOptions);
        },
        setFlaggedProperties(props: any) {
            this.description = props.description;
            this.comment = props.comment;
            this.$bvModal.show("modal-flagged-comment");
        },
        async fetchReportById() {
            const { id } = this.$route.params;
            this.isLoading = true;
            try {
                const res = await ReportService.fetchReportById(id as string);
                this.report = res.data;
                this.$store.commit("reports/SET_REPORT_BY_ID", res.data);
            } catch (error) {
                this.isLoading = false;
            } finally {
                this.isLoading = false;
            }
        },
        checkForUnresolvedCommentsBeforeApproval() {
            let reportHasUnresolvedComments = false;
            if (this.actionIsForSectorialRegulator) {
                reportHasUnresolvedComments = this.report.comments.filter((comment: any) => comment.regulatorId === this.currentTab.value).some((comment: any) => !comment.resolved);
            } else {
                reportHasUnresolvedComments = this.report.comments.some((comment: any) => !comment.regulatorId && !comment.resolved);
            }
            if (reportHasUnresolvedComments) {
                this.$bvModal.show("modal-report-confirmation-approve-report");
            } else {
                this.approveReport();
            }
        },
        async changeSectorialRegulatorEditStatus(editStatus: number) {
            const { id } = this.$route.params;
            const body = { editStatus };
            try {
                await ReportService.setSectorialRequirementStatus({ reportId: id, regulatorId: this.currentTab.value }, body);
                this.$bvToast.toast(editStatus === 2 ? "Approved successfully" : "Report sent back to creator", { title: "Success!", variant: "success", solid: true });
                this.report.sectorialRequirementsReviews = this.report.sectorialRequirementsReviews.map((review: any) => {
                    if (review.regulatorId === this.currentTab.value) return { ...review, editStatus };
                    return review;
                });
            } catch {
                this.isApprovingReport = false;
                this.isSendingBackToCreator = false;
            }
        },
        async approveReport() {
            this.$bvModal.hide("modal-report-confirmation-approve-report");
            const { id } = this.$route.params;
            this.isApprovingReport = true;
            if (this.actionIsForSectorialRegulator) {
                await this.changeSectorialRegulatorEditStatus(2);
                this.isApprovingReport = false;
            } else {
                const body = { resolution: 2, comment: "" };
                try {
                    const res = await ReportService.reviewReport({ id: id as string, body });
                    this.$bvToast.toast(res.data.message, { title: "Success!", variant: "success", solid: true });
                    this.fetchReportById();
                    this.isApprovingReport = false;
                } catch (error) {
                    this.isApprovingReport = false;
                }
            }
        },
        async signReport(val: string) {
            const { id } = this.$route.params;
            this.isSigning = true;
            try {
                const res = await ReportService.signReport({ id: id as string, body: val });
                this.$bvToast.toast(res.data.message, { title: "Success!", variant: "success", solid: true });
                await this.fetchReportById();
            } catch (error) {
                this.isSigning = false;
            } finally {
                this.isSigning = false;
                this.$bvModal.hide("modal-upload-signature");
                await this.fetchReportById();
            }
        },
        async submitToRegulator() {
            if (this.disableSubmission) return;
            const { id } = this.$route.params;
            this.isSubmittingToRegulator = true;
            try {
                const res = await ReportService.sendToRegulator(id as string);
                if (res?.data?.responseObj?.length > 0) {
                    const totalAmount = res.data.responseObj.reduce((acc: number, current: any) => {
                        return acc + current.fee;
                    }, 0);
                    this.totalAmount = totalAmount;
                    this.outstandingFees = res.data.responseObj;
                }
                if (res?.data?.statusCode === "01") {
                    this.$bvModal.show("modal-make-payment");
                } else {
                    this.$bvToast.toast("Report sent to regulator successfully!", { title: "Success!", variant: "success", solid: true });
                    await this.fetchReportById();
                }
            } catch (error) {
                this.isSubmittingToRegulator = false;
            } finally {
                this.isSubmittingToRegulator = false;
            }
        },
        async submitToSectorialRegulator() {
            const { id: reportId } = this.$route.params;
            this.isSubmittingToRegulator = true;
            const payload = { reportId, regulatorId: this.currentTab.value };
            try {
                await ReportService.submitForSectorialRegulatorReview(payload);
                // if (response?.data?.statusCode === "01") {
                //     this.$bvModal.show("modal-make-payment");
                //     this.totalAmount = response.data.amount;
                //     this.outstandingFees = [{ fee: response.data.amount, feeCategory: "SUBMISSION FEE", reportCode: reportId }];
                // } else {
                this.$bvToast.toast("Report sent to regulator successfully!", { title: "Success!", variant: "success", solid: true });
                this.report.sectorialRequirementsReviews = this.report.sectorialRequirementsReviews.map((review: any) => {
                    if (review.regulatorId === this.currentTab.value) return { ...review, editStatus: 0, currentRegulatorDeskId: "id" };
                    return review;
                });
                // }
            } catch (error) {
                this.isSubmittingToRegulator = false;
            }
        },
        fetchFromApplicableSection(toSearch: any, keyWord: string) {
            const found = toSearch.find((value: any) => {
                return value.name === keyWord || value.identity === keyWord || value.normalizedPosition === keyWord;
            });
            return found;
        },
        retrieveFlaggedSections(payload: any) {
            // Company Tab
            const license = this.fetchFromApplicableSection(payload.applicableSections, "License");
            const policy = this.fetchFromApplicableSection(payload.applicableSections, "Policy");
            const company = {
                license,
                policy,
            };
            // End Company Tab

            // Stakeholders Tab
            const auditor = this.fetchFromApplicableSection(payload.applicableSections, "Auditor");
            const registrar = this.fetchFromApplicableSection(payload.applicableSections, "Registrar");
            const governanceConsultant = this.fetchFromApplicableSection(payload.applicableSections, "Governance Consultant");
            const boardConsultant = this.fetchFromApplicableSection(payload.applicableSections, "Board Consultant");
            const charter = this.fetchFromApplicableSection(payload.applicableSections, "Charter");

            const stakeholders = {
                auditor,
                charter,
                registrar,
                boardConsultant,
                governanceConsultant,
            };
            // End Stakeholders Tab

            // Structures Tab
            const complianceOfficers = this.fetchFromApplicableSection(payload.structure.structurePositions, "S5");
            const executives = this.fetchFromApplicableSection(payload.structure.structurePositions, "S1");
            const secretarys = this.fetchFromApplicableSection(payload.structure.structurePositions, "S4");
            const contactPersons = this.fetchFromApplicableSection(payload.structure.structurePositions, "S2");
            const boardOfDirectors = this.fetchFromApplicableSection(payload.structure.structurePositions, "S3");
            const investorRelations = this.fetchFromApplicableSection(payload.structure.structurePositions, "S6");
            const structure = {
                complianceOfficers,
                executives,
                secretarys,
                contactPersons,
                boardOfDirectors,
                investorRelations,
            };
            // End structures Tab

            // Signatures Tab
            const chairmanOfBoard = this.fetchFromApplicableSection(payload.signatures, "SN2");
            const companySecretary = this.fetchFromApplicableSection(payload.signatures, "SN4");
            const managingDirector = this.fetchFromApplicableSection(payload.signatures, "SN1");
            const chairmanOfCommittee = this.fetchFromApplicableSection(payload.signatures, "SN3");
            const certification = {
                chairmanOfBoard,
                managingDirector,
                companySecretary,
                chairmanOfCommittee,
            };
            // End signatures Tab

            // Documentations Tab
            // const proofOfAddress = this.fetchFromApplicableSection(
            //     payload.documents,
            //     "Proof of registered address",
            // );
            const otherDocuments = this.fetchFromApplicableSection(payload.documents, "Other documents");
            // const proofOfBusinessLicense = this.fetchFromApplicableSection(
            //     payload.documents,
            //     "Proof of business License",
            // );
            // const certificationOfIncorporation =
            //     this.fetchFromApplicableSection(
            //         payload.documents,
            //         "Certificate of Incorporation",
            //     );
            const attendanceRegister = this.fetchFromApplicableSection(payload.documents, "Attendance Register");

            const primaryOperatingLicense = this.fetchFromApplicableSection(payload.documents, "Primary Operating License");
            // const minuteBook = this.fetchFromApplicableSection(
            //     payload.documents,
            //     "Minute Book",
            // );
            // const memorandum = this.fetchFromApplicableSection(
            //     payload.documents,
            //     "Memorandum & Articles of Association",
            // );
            const documentation = {
                attendanceRegister,
                primaryOperatingLicense,
                otherDocuments,
                // minuteBook,
                // memorandum,
                // proofOfAddress,
                // proofOfBusinessLicense,
                // certificationOfIncorporation,
            };
            // End Documentations Tab

            this.flaggedSections = { company, structure, stakeholders, certification, documentation };
        },
        calculateFlaggedSections() {
            const count = this.checkRegulatorStatusFlags
                ? this.report.principles.reduce((prev: any, cur: any) => {
                      return cur.isFlagged ? prev + 1 : prev + 0;
                  }, 0)
                : 0;

            this.count = count;
        },
        async sendReportBackToCreator(withComments = false) {
            if (withComments) {
                this.$bvToast.toast("Sending report back to Creator...", { title: "Success!", variant: "success", solid: true });
            }
            const { id } = this.$route.params;
            this.isSendingBackToCreator = true;
            if (this.actionIsForSectorialRegulator) {
                await this.changeSectorialRegulatorEditStatus(0);
                this.isSendingBackToCreator = false;
            } else {
                try {
                    this.isSendingBackToCreator = true;
                    const response = await ReportService.backToCreator(id, '');
                    this.$bvModal.hide("modal-report-confirmation-submit-review");
                    this.isSendingBackToCreator = false;
                    this.$bvToast.toast(response.data.message, { title: "Success!", variant: "success", solid: true });
                    await this.fetchReportById();
                } catch {
                    this.isSendingBackToCreator = false;
                    this.$bvToast.toast("Please check your connection or try again later", { title: "Something went wrong", variant: "danger", solid: true });
                }
            }
        },
        toggleReviewComment() {
            this.isShowingReviewComments = !this.isShowingReviewComments;
        },
        goToSectorialRegulatorSubmission(regulator: string) {
            const { id } = this.$route.params;
            this.$router.push({ name: "reports.submit", params: { id, regulator } });
        },
        openReviewConfirmationModal() {
            this.confirmationType = "submit-review";
            this.isShowingReviewComments = false;
            this.$bvModal.show("modal-report-confirmation-submit-review");
        },
        toggleShowCurrentDeskUsersDropdown(value: boolean) {
            this.showCurrentDeskUsersDropdown = value;
        },
    },
    watch: {
        report(val) {
            this.retrieveFlaggedSections(val);
            this.calculateFlaggedSections();
        },
    },
    async mounted() {
        this.isLoading = true;
        const { id } = this.$route.params;
        if (id) {
            await this.fetchReportById();
        }
        this.isLoading = false;
        this.isShowingReviewComments = (this.isCreator || this.canApproveReport) && this.report.reportInformation.reportStatus !== 12 && this.report.comments && this.report.comments.length > 0;
    },
});
