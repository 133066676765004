
import Vue from "vue";
import sha512 from "js-sha512";
import { email, required } from "vuelidate/lib/validators";
import { formatMoney, capitalizeFirstLetter } from "../../../utils/helpers";
import PaymentService from "../../../api/payment-service";

Vue.prototype.Window = window;

export default Vue.extend({
    name: "PaymentModal",
    props: {
        totalAmount: {
            default: 0,
        },
        reportID: {
            default: "",
            type: String,
        },
        regulatorID: {
            default: "",
        },
        templateID: {
            default: "",
        },
        outstandingFees: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            sha512,
            isGenerating: false,
            form: {
                payerName: "",
                payerEmail: "",
                payerNumber: "",
            },
            generatedRRR: "",
            merchantId: "",
            remitaAPI: "",
            formatMoney,
            capitalizeFirstLetter,
        };
    },
    methods: {
        async makePayment() {
            const data = {
                payerName: this.form.payerName,
                payerEmail: this.form.payerEmail,
                payerPhone: this.form.payerNumber,
                description: "Payment for report",
                reportId: this.reportID,
                regulatorId: this.regulatorID,
                templateId: this.templateID,
            };

            try {
                this.isGenerating = true;

                const res = await PaymentService.generateInvoice(
                    this.reportID,
                    data,
                );

                if (res.status === 200) {
                    const responseData = res.data;
                    this.generatedRRR = responseData.rrr;
                    this.merchantId = responseData.data.merchandId;
                    this.remitaAPI = responseData.data.apikey;
                    this.$bvToast.toast(
                        "Payment reference has been generated. Click 'pay with Remita' to Pay",
                        {
                            title: "Success!",
                            variant: "success",
                            solid: true,
                        },
                    );
                }
            } catch (error) {
                this.isGenerating = false;
            } finally {
                this.isGenerating = false;
            }
        },
    },
    validations() {
        return {
            form: {
                payerName: {
                    required,
                },
                payerEmail: {
                    email,
                    required,
                },
                payerNumber: {
                    required,
                },
            },
        };
    },
});
