
import Vue from "vue";

export default Vue.extend({
    name: "MessageModal",
    props: {
        message: {
            type: String
        }
    },
    data(): any {
        return {
        };
    },
    computed: {
    },
    methods: {
    },
});
