
import Vue from "vue";
import ReportService from "../../../api/report-service";

export default Vue.extend({
    name: "SendBackModal",
    data() {
        return {
            comment: "",
            isLoading: false,
        };
    },
    methods: {
        closeModal() {
            this.$bvModal.hide("modal-send-back");
        },
        async sendBackToCreator() {
            this.isLoading = true;
            const { id } = this.$route.params;
            try {
                const response = await ReportService.backToCreator(id, this.comment);
                if (response && response.status === 200) {
                    this.$bvToast.toast("Report have been sent back to creator", { title: "Success!", variant: "success", solid: true });
                    this.$bvModal.hide("modal-send-back");
                    setTimeout(() => {
                        this.$emit("report-sent");
                    }, 1000);
                }
            } finally {
                this.isLoading = false;
            }
            console.log("submitting back to creator");
        },
    },
});
