
import Vue from "vue";
import { DateTime } from "luxon";

export default Vue.extend({
    name: "ReportHistoryModal",
    props: {
        report: {
            type: Object,
        },
    },
    data() {
        return {
            DateTime,
            history: [
                { date: "19/04/2023", time: "2:15 PM", title: "Report submitted to PENCOM", user: { firstName: "Oluwasogo", lastName: "Adejuwon" } },
                { date: "17/04/2023", time: "2:15 PM", title: "Report approved for submission to PENCOM", user: { firstName: "Sophia", lastName: "Akinbunmi" } },
                { date: "17/04/2023", time: "9:15 AM", title: "PENCOM report submitted for internal review", user: { firstName: "Oluwasogo", lastName: "Adejuwon" } },
                { date: "14/04/2023", time: "2:15 PM", title: "PENCOM report draft created", user: { firstName: "Oluwasogo", lastName: "Adejuwon" } },
                { date: "31/03/2023", time: "4:15 PM", title: "Report submitted to FRC", user: { firstName: "Oluwasogo", lastName: "Adejuwon" } },
                { date: "30/03/2023", time: "12:15 PM", title: "Report signed by Board Chairman", user: { firstName: "Emmanuel", lastName: "Oluseye" } },
                { date: "30/03/2023", time: "9:20 AM", title: "Report signed by Company Secretary/Compliance Officer", user: { firstName: "Diane", lastName: "Williams" } },
                { date: "29/03/2023", time: "2:15 PM", title: "Report approved for submission", user: { firstName: "Grace", lastName: "James" } },
                { date: "29/03/2023", time: "1:15 PM", title: "Report submitted for internal review", user: { firstName: "Oluwasogo", lastName: "Adejuwon" } },
                { date: "29/03/2023", time: "12:36 PM", title: "Report internal review declined", user: { firstName: "Grace", lastName: "James" } },
                { date: "29/03/2023", time: "11:23 AM", title: "Report submitted for internal review", user: { firstName: "Oluwasogo", lastName: "Adejuwon" } },
                { date: "26/03/2023", time: "9:40 AM", title: "Report created", user: { firstName: "Oluwasogo", lastName: "Adejuwon" } },
            ],
        };
    },
});
