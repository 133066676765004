var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    attrs: {
      "id": "report-history",
      "title": "Report History",
      "description": "Learn more about how this report has changed over time.",
      "apply-body-classes": false,
      "has-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "history-footer"
        }, [_c('ba-button', {
          staticClass: "text-black",
          attrs: {
            "text": "Close",
            "variant": "light"
          },
          on: {
            "click": function ($event) {
              return _vm.$bvModal.hide('modal-report-history');
            }
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "history-modal"
  }, [_c('div', {
    staticClass: "history-header d-flex"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Report ID")]), _c('h6', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.report.reportCode))])]), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Report Timeline")]), _c('h6', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.DateTime.fromISO(_vm.report.reportingTimelineFrom).toFormat("LLLL d, yyyy")) + " - " + _vm._s(_vm.DateTime.fromISO(_vm.report.reportingTimelineTo).toFormat("LLLL d, yyyy")) + " ")])])]), _c('div', {
    staticClass: "history-content"
  }, [_c('p', {
    staticClass: "header mb-0"
  }, [_vm._v("From latest to earliest activity")]), _c('ul', {
    staticClass: "history-content__list list-unstyled d-flex flex-column"
  }, _vm._l(_vm.history, function (item, index) {
    return _c('li', {
      key: index,
      staticClass: "history-item d-flex"
    }, [_c('div', {
      staticClass: "history-item__date-time d-flex flex-column"
    }, [_c('p', {
      staticClass: "history-item__date mb-0"
    }, [_vm._v(_vm._s(item.date))]), _c('p', {
      staticClass: "history-item__time mb-0 text-right"
    }, [_vm._v(_vm._s(item.time))])]), _c('div', {
      staticClass: "history-item__content d-flex flex-column"
    }, [_c('p', {
      staticClass: "history-item__title mb-0"
    }, [_vm._v(_vm._s(item.title))]), _c('p', {
      staticClass: "mb-0 history-item__user d-flex align-items-center"
    }, [_c('span', {
      staticClass: "by"
    }, [_vm._v("by")]), _c('BaUser', {
      attrs: {
        "user": item.user,
        "show-name": ""
      }
    })], 1)])]);
  }), 0)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }