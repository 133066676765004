var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    staticClass: "selectable-modal selectable-modal_container",
    attrs: {
      "id": 'make-payment',
      "title": 'Make Payment',
      "description": 'NB: You are strongly advised to process payment for your report via this portal in order to enable the system validate your payment and update your report automatically.',
      "isPaymentModal": true,
      "custom-content-class": "overflow-scroll selectable-modal__content"
    }
  }, [_c('b-row', {
    staticClass: "my-3"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-subheader', [_c('div', {
    staticClass: "text-sm-2"
  }, [_vm._v("Payment Breakdown")])]), _c('div', {
    staticClass: "my-4"
  }, [_c('div', {
    staticClass: "text-sm-2 my-0 py-0"
  }, [_vm._v(" List of Fees to make payment on ")]), _vm._l(_vm.outstandingFees, function (outstandingFee, index) {
    return _c('div', {
      key: index,
      staticClass: "text-black mt-3 text-sm-3"
    }, [_c('b', [_vm._v(_vm._s(_vm.capitalizeFirstLetter(outstandingFee.feeCategory)))]), _vm._v(" - " + _vm._s(_vm.formatMoney(outstandingFee.fee)) + " ")]);
  })], 2)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "Payer Name",
      "placeholder": "e.g David Ajayi"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.payerName.$touch();
      }
    },
    model: {
      value: _vm.form.payerName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "payerName", $$v);
      },
      expression: "form.payerName"
    }
  }), _vm.$v.form.payerName.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Payer Name is required ")]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "Payer Email",
      "placeholder": "e.g david.ajayi.anu@gmail.com"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.payerEmail.$touch();
      }
    },
    model: {
      value: _vm.form.payerEmail,
      callback: function ($$v) {
        _vm.$set(_vm.form, "payerEmail", $$v);
      },
      expression: "form.payerEmail"
    }
  }), _vm.$v.form.payerEmail.$error ? _c('div', {
    staticClass: "error"
  }, [!_vm.$v.form.payerEmail.required ? _c('div', [_vm._v(" Payer Email is required ")]) : _vm._e(), !_vm.$v.form.payerEmail.email ? _c('div', [_vm._v(" Payer Email is not valid ")]) : _vm._e()]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "Payer Phone Number",
      "placeholder": "e.g (+234) 405 239 2399"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.payerNumber.$touch();
      }
    },
    model: {
      value: _vm.form.payerNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "payerNumber", $$v);
      },
      expression: "form.payerNumber"
    }
  }), _vm.$v.form.payerNumber.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Payer Phone number is required ")]) : _vm._e()], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "disabled": "",
      "label": "Total Amount"
    },
    model: {
      value: _vm.totalAmount,
      callback: function ($$v) {
        _vm.totalAmount = $$v;
      },
      expression: "totalAmount"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "disabled": "",
      "label": "Report ID"
    },
    model: {
      value: _vm.reportID,
      callback: function ($$v) {
        _vm.reportID = $$v;
      },
      expression: "reportID"
    }
  })], 1)], 1), _vm.generatedRRR ? _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-text-field', {
    attrs: {
      "disabled": "",
      "label": "Generated RRR"
    },
    model: {
      value: _vm.generatedRRR,
      callback: function ($$v) {
        _vm.generatedRRR = $$v;
      },
      expression: "generatedRRR"
    }
  })], 1)], 1) : _vm._e(), _c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-subheader', [_c('div', {
    staticClass: "text-sm-2"
  }, [_vm._v("Payment Summary")])]), _c('div', {
    staticClass: "my-4"
  }, [_c('div', {
    staticClass: "text-black mt-3 text-sm-3"
  }, [_c('b', [_vm._v("Name")]), _vm._v(" - " + _vm._s(_vm.capitalizeFirstLetter(_vm.form.payerName) || "No Name Entered") + " ")]), _c('div', {
    staticClass: "text-black mt-3 text-sm-3"
  }, [_c('b', [_vm._v("Email")]), _vm._v(" - " + _vm._s(_vm.capitalizeFirstLetter(_vm.form.payerEmail) || "No Email Entered") + " ")]), _c('div', {
    staticClass: "text-black mt-3 text-sm-3"
  }, [_c('b', [_vm._v("Phone Number")]), _vm._v(" - " + _vm._s(_vm.capitalizeFirstLetter(_vm.form.payerNumber) || "No Phone Number Entered") + " ")]), _c('div', {
    staticClass: "text-black mt-3 text-sm-3"
  }, [_c('b', [_vm._v("Amount")]), _vm._v(" - " + _vm._s(_vm.formatMoney(_vm.totalAmount)) + " ")])])], 1)], 1), _c('b-row', {
    staticClass: "mt-auto"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center mt-2"
  }, [_vm.generatedRRR ? _c('form', {
    attrs: {
      "action": "https://login.remita.net/remita/ecomm/finalize.reg",
      "method": "POST"
    }
  }, [_c('input', {
    attrs: {
      "name": "merchantId",
      "type": "hidden"
    },
    domProps: {
      "value": _vm.merchantId
    }
  }), _c('input', {
    attrs: {
      "name": "hash",
      "type": "hidden"
    },
    domProps: {
      "value": _vm.sha512.sha512(_vm.merchantId + _vm.generatedRRR + _vm.remitaAPI)
    }
  }), _c('input', {
    attrs: {
      "name": "rrr",
      "type": "hidden"
    },
    domProps: {
      "value": _vm.generatedRRR
    }
  }), _c('input', {
    attrs: {
      "name": "responseurl",
      "type": "hidden"
    },
    domProps: {
      "value": `${_vm.Window.location.origin}/successful-payment`
    }
  }), _vm.generatedRRR ? _c('ba-button', {
    staticClass: "text-black",
    attrs: {
      "type": 'submit',
      "text": "Pay with Remita",
      "id": "BA_AMP_PAY_WITH_REMITTA",
      "variant": "warning"
    }
  }) : _vm._e()], 1) : _c('ba-button', {
    staticClass: "text-black",
    attrs: {
      "loading": _vm.isGenerating,
      "disabled": _vm.$v.$invalid || _vm.isGenerating,
      "text": "Generate RRR",
      "variant": "warning"
    },
    on: {
      "click": _vm.makePayment
    }
  })], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }