var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    attrs: {
      "id": "fines-penalties",
      "title": "Fines/Penalties",
      "description": "See below all fines/penalties issued to your company.",
      "apply-body-classes": false
    }
  }, [_c('div', {
    staticClass: "fines-modal d-flex flex-column"
  }, [_c('div', {
    staticClass: "fines-modal__header d-flex"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('p', [_vm._v("Report ID")]), _c('h6', [_vm._v("RX-2022019")])]), _c('div', {
    staticClass: "content"
  }, [_c('p', [_vm._v("Report Timeline")]), _c('h6', [_vm._v("Mar 3, 2003 - Mar 3, 2004")])]), _c('div', {
    staticClass: "content"
  }, [_c('p', [_vm._v("Date Submitted to FRC")]), _c('h6', [_vm._v("19/11/2022, 2:19PM")])])]), _c('div', {
    staticClass: "fines-modal__content d-flex flex-column"
  }, [_c('BaTabs', {
    attrs: {
      "tabs": _vm.tabs,
      "active-tab": _vm.fineStatus
    },
    on: {
      "set-active-tab": function ($event) {
        _vm.fineStatus = $event;
      }
    }
  }), _c('ul', {
    staticClass: "list-unstyled fines-modal__fines d-flex flex-column"
  }, _vm._l(_vm.filteredFines, function (fine, index) {
    return _c('li', {
      key: index,
      staticClass: "fine"
    }, [_c('div', {
      staticClass: "fine__header"
    }, [_c('div', {
      staticClass: "content"
    }, [_c('p', [_vm._v("Regulator")]), _c('h6', [_vm._v(_vm._s(fine.regulator))])]), _c('div', {
      staticClass: "content"
    }, [_c('p', [_vm._v("Date fined")]), _c('h6', [_vm._v(_vm._s(fine.dateFined))])]), _c('div', {
      staticClass: "content"
    }, [_c('p', [_vm._v("Fine/Penalty Amount")]), _c('h6', [_vm._v(_vm._s(fine.amount))])])]), _c('div', {
      staticClass: "fine__actions d-flex align-items-center justify-content-between"
    }, [_c('span', {
      staticClass: "fine__status",
      class: `fine__status--${fine.status}`
    }, [_vm._v(_vm._s(fine.status))]), _c('div', {
      staticClass: "d-flex fine__buttons"
    }, [_c('button', [_vm._v("View details")]), _c('button', [_vm._v("Pay")])])])]);
  }), 0)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }