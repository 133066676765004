
import Vue from "vue";
import pencom from "@/assets/providers/pencom";
import cbn from "@/assets/providers/cbn";
import sec from "@/assets/providers/sec";
import ngx from "@/assets/providers/ngx";
import naicom from "@/assets/providers/naicom";
import SectorialRequirementOutput from "../../reports/SectorialRequirementOutput.vue";

export default Vue.extend({
    props: {
        isLoading: { type: Boolean, required: false, default: true },
        currentTab: { type: Object, required: true },
        report: { type: Object, required: true },
    },
    components: { SectorialRequirementOutput },
    computed: {
        principles(): any {
            let principles = [];
            switch (this.currentTab.value) {
                case "sec":
                    principles = sec;
                    break;
                case "naicom":
                    principles = naicom;
                    break;
                case "pencom":
                    principles = pencom;
                    break;
                case "ngx":
                    principles = ngx;
                    break;
                case "cbn":
                    principles = cbn;
                    break;
                default:
                    break;
            }
            const answers = this.report.sectorialInputModels.filter((model: any) => model.regulatorId === this.currentTab.value);
            return principles.map((principle: any) => {
                return {
                    ...principle,
                    questions: principle.questions.map((question: any) => {
                        const answerToPrinciple = answers.find((answer: any) => answer.entry === question.entryName);
                        return {
                            ...question,
                            textValue: answerToPrinciple?.textValue ?? "",
                            dropDownValue: answerToPrinciple?.dropDownValue ?? "",
                            booleanValue: answerToPrinciple?.booleanValue ?? null,
                            documents: [],
                            ...(answerToPrinciple &&
                                answerToPrinciple.documents &&
                                answerToPrinciple.documents.length > 0 && {
                                    documents: answerToPrinciple.documents.map(({ docSize, docId, docName }: any) => {
                                        return { fileName: docName, docUploadId: docId, size: docSize };
                                    }),
                                }),
                        };
                    }),
                };
            });
        },
        documents(): any {
            const regulator = this.report.sectorialInputModels.find((model: any) => model.regulatorId === this.currentTab.value && model.entry === "documentation");
            if (regulator)
                return regulator.documents.map(({ docSize, docId, docName }: any) => {
                    return { id: Math.random().toString(36).substring(7), fileName: docName, docUploadId: docId, size: docSize };
                });
            return [];
        },
    },
});
