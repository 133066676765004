var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "output"
  }, [_c('span', {
    staticClass: "output__name text-black-70 d-block"
  }, [_vm._v(_vm._s(_vm.question.uniqueName))]), _c('div', {
    staticClass: "output__body d-flex flex-column bg-white"
  }, [_c('span', {
    staticClass: "output__question",
    domProps: {
      "innerHTML": _vm._s(_vm.question.question)
    }
  }), _vm.questionHaveBooleanInput ? _c('div', {
    staticClass: "output__answer d-flex flex-column"
  }, [_c('span', [_vm._v("Option")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.question.booleanValue ? "Yes" : "No"))])]) : _vm._e(), _vm.questionHaveDropdownInput ? _c('div', {
    staticClass: "output__answer d-flex flex-column"
  }, [_c('span', [_vm._v("Answer")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.question.dropDownValue || "Not provided"))])]) : _vm._e(), _c('div', {
    staticClass: "output__answer d-flex flex-column"
  }, [_c('span', [_vm._v("Explanation")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.question.textValue || "Not provided"))])]), _c('div', {
    staticClass: "output__answer d-flex flex-column"
  }, [_c('span', [_vm._v("Supporting document")]), _vm.question.documents.length > 0 ? _c('ba-upload-field', {
    attrs: {
      "saved-files": _vm.question.documents,
      "is-removable": false,
      "id": `${_vm.question.uniqueName}-${_vm.question.innerOrderIndex}`
    }
  }) : _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Not provided")])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }