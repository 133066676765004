
import Vue from "vue";

export default Vue.extend({
    name: "SubmitToOthersModal",
    props: {
        isLoading: { type: Boolean, default: false },
        submittedRegulators: { type: Array, required: true, default: () => [] },
    },
    data() {
        return {
            selectedRegulator: "",
        };
    },
    computed: {
        regulators(): any {
            return [
                { id: "pencom", image: "pencom.png", name: "National Pension Commission (PENCOM)" },
                { id: "cbn", image: "cbn.png", name: "Central Bank of Nigeria (CBN)" },
                { id: "sec", image: "sec.png", name: "Securities and Exchange Commission (SEC)" },
                { id: "ngx", image: "ngx.png", name: "Nigerian Exchange Group (NGX)" },
                { id: "naicom", image: "naicom.png", name: "National Insurance Commission (NAICOM)" },
            ].map((regulator) => {
                const submittedRegulator: any = this.submittedRegulators.find((item: any) => item.regulatorId === regulator.id);
                return {
                    ...regulator,
                    isSubmitted: submittedRegulator && submittedRegulator.editStatus === 1,
                };
            });
        },
    },
    methods: {
        closeModal() {
            this.$bvModal.hide("modal-submit-to-others");
        },
        selectRegulator(regulator: any) {
            if (!regulator.isSubmitted) this.selectedRegulator = regulator.id;
        },
        proceedToSubmitPage() {
            const { id } = this.$route.params;
            this.$router.push({ name: "reports.submit", params: { id, regulator: this.selectedRegulator } });
        },
    },
});
